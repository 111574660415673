<template>
  <v-form ref="blog-form" @submit.prevent="formSubmit">
    <v-row>
      <v-col cols="12" class="d-flex">
        <v-btn @click.prevent="$router.replace({ name: 'blog' })" plain :disabled="isFetching">
          <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
          <div>上一頁</div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card rounded="lg" class="pa-5">
          <v-card-subtitle>
            <div class="page-title">{{ $route.meta.title }}</div>
          </v-card-subtitle>
          <v-card-text class="my-5">
            <v-row>
              <v-col md="12" lg="6" cols="12">
                <h3 class="mb-3">日誌封面</h3>
                <FormButtonUpload
                  :uploadFile.sync="cover"
                  :showUploadFileName="false"
                  :showUploadPreviewImg="true"
                ></FormButtonUpload>
                <div class="error-msg mt-2">{{ coverError }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <form-control
                  inputType="string"
                  v-model="title"
                  label="日誌標題"
                  :required="true"
                  :disabled="isFetching"
                />
              </v-col>
              <v-col md="6" cols="12">
                <form-control
                  inputType="select"
                  v-model="blogCategoryID"
                  :options="categoryList"
                  label="日誌分類"
                  :required="true"
                  :disabled="isFetching"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <form-control
                  inputType="string"
                  v-model="path"
                  label="日誌URL"
                  :required="true"
                  :disabled="isFetching"
                  :customChecking="[
                    v => {
                      return $validate.urlValid(v)
                    },
                  ]"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h3 class="mb-3">內容</h3>
                <QuillEditor editorID="editor" :html.sync="content"></QuillEditor>
                <div class="error-msg mt-2">{{ contentError }}</div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn color="primary" class="px-6" small depressed :disabled="isFetching" type="submit">儲存</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>
 
 <script>
import { mapActions, mapState } from 'vuex'
import FormControl from '@/components/form/FormControl.vue'
import { mdiChevronLeft } from '@mdi/js'
import FormButtonUpload from '@/components/form/FormButtonUpload.vue'
import QuillEditor from '@/components/QuillEditor'

export default {
  name: 'BlogDetail',
  components: {
    FormControl,
    FormButtonUpload,
    QuillEditor,
  },
  setup() {
    return {
      icons: {
        mdiChevronLeft,
      },
    }
  },
  data() {
    return {
      cover: '',
      saveCover: '',
      title: '',
      blogCategoryID: '',
      content: '',
      path: '',

      contentError: '',
      saveContentImage: [],
      coverError: '',

      dbCategoryData: [],

      submitSuccess: false,
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),

    id: function () {
      return this.$route.params.id
    },

    categoryList: function () {
      return this.dbCategoryData.map(obj => {
        return { text: obj.category_name, value: obj.id }
      })
    },
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    ...mapActions(['setDialogMessage', 'setShowDialog']),

    async getBlogCategory() {
      try {
        const payload = {}

        const result = await this.$XHR.api('get_blog_category', payload)
        this.$func.log('-----Get Blog Category-----')
        this.$func.log(result)

        this.dbCategoryData = result.data
      } catch (error) {
        this.$func.log('-----Get Blog Category Fail-----')
        this.$func.log(error)
        this.dbCategoryData = []
      } finally {
        this.setFetching(false)
      }
    },

    async getBlogDetail() {
      let blogError = false
      let message = '資料不存在'

      try {
        const payload = {
          id: parseInt(this.id),
        }

        const result = await this.$XHR.api('get_blog_by_id', payload)
        this.$func.log('-----Get Blog Detail-----')
        this.$func.log(result)

        this.cover = result.cover
        this.saveContentImage = result.cover
        this.title = result.title
        this.blogCategoryID = parseInt(result.blog_category_id)
        this.content = result.content
        this.path = result.blog_path

        this.saveContentImage = this.$func.getMediaInsideHTMLString(this.content)

        this.$store.dispatch('toggleLoadingPage', false)
      } catch (error) {
        this.$func.log('-----Get Blog Fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          blogError = true
          message = '沒有權限'
        } else {
          blogError = true
        }
      } finally {
        this.setFetching(false)
      }

      if (blogError) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message,
          type: 'error',
          refresh: false,
          redirect: 'blog',
        })
      }
    },

    async formSubmit() {
      if (!this.isFetching) {
        this.setFetching(true)

        this.contentError = ''
        this.coverError = ''

        const isValid = this.$refs['blog-form'].validate()

        if (!this.$validate.DataValid(this.content)) {
          this.contentError = '請輸入內容'
        }

        if (!this.$validate.DataValid(this.cover)) {
          this.coverError = '請上傳封面'
        }

        if (!isValid || this.$validate.DataValid(this.contentError) || this.$validate.DataValid(this.coverError)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請先填滿所有必填項目',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          this.setFetching(false)
          return
        }

        try {
          const payload = {
            verify_token: this.getAdminToken(),
            data_type: 'blog_data',
            data_status: 'active',
            status: 'active',
            cover: this.cover,
            title: this.title,
            blog_category_id: parseInt(this.blogCategoryID),
            content: this.content,
            blog_path: this.path,
          }

          let key = ''
          if (this.$validate.DataValid(this.id)) {
            payload.id = parseInt(this.id)
            key = 'cms_update_blog'
          } else {
            key = 'cms_add_blog'
          }

          const result = await this.$XHR.api(key, payload)
          this.$func.log('-----Update Blog-----')
          this.$func.log(result)

          this.submitSuccess = true

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: { name: 'blog' },
          })

          this.setShowDialog(true)
          this.setFetching(false)
        } catch (error) {
          this.$func.log('-----Update Blog Fail-----')
          this.$func.log(error)

          let message = '儲存失敗'

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else if (error.data === 'no permission') {
            message = '沒有權限'
          } else if (error.data === 'blog category not found') {
            message = '找不到日誌分類'
          } else if (error.data === 'blog not found') {
            message = '找不到日誌'
          }

          this.setFetching(false)
          this.submitSuccess = false
          this.setDialogMessage({
            message: message,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    this.setFetching(true)
    const check = await this.checkUserData()
    if (check) {
      this.getBlogCategory()

      if (!this.$validate.DataValid(this.id)) {
        this.$store.dispatch('toggleLoadingPage', false)
      } else {
        this.getBlogDetail()
      }
    }
  },
  async beforeDestroy() {
    let removeList = []
    if (this.$store.getters.removedImageList.length > 0) {
      removeList = removeList.concat(this.$store.getters.removedImageList)
    }

    if (this.submitSuccess) {
      if (this.saveCover !== this.cover) {
        const index = removeList.indexOf(this.cover)
        if (index !== -1) {
          removeList.splice(index, 1)
        }

        removeList.push(this.saveCover)
      }

      const imgList = this.$func.getMediaInsideHTMLString(this.content)
      for (let i = 0; i < removeList.length; i++) {
        const index = imgList.indexOf(removeList[i])
        if (index !== -1) {
          removeList.splice(index, 1)
        }
      }
    } else {
      for (let i = 0; i < removeList.length; i++) {
        const index = this.saveContentImage.indexOf(removeList[i])
        if (index !== -1) {
          removeList.splice(index, 1)
        }
      }
    }

    if (removeList.length > 0) {
      const removeImage = await this.$XHR.api('cms_remove_media', {
        verify_token: this.getAdminToken(),
        remove_image_list: removeList,
      })

      this.$func.log('--- remove image success ---')
      this.$func.log(removeImage)
    }

    this.$store.dispatch('resetRemovedImage', [])
  },
}
</script>
 